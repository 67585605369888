.c-business-information-form__body {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--g-spacing-x-large);
}

.c-business-information-form__field {
  margin-bottom: var(--g-spacing);
  text-align: left;
  width: 100%;
}
