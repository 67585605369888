.c-rules-view {
  padding: var(--spacing-4);
}

.c-rules-view__html-configuration,
.c-rules-view__html-configuration-container,
.c-rules-view__html-configuration-delete-icon {
  align-items: center;
  display: flex;
}

.c-rules-view__html-configuration-container {
  border-radius: var(--spacing-1);
  margin: var(--spacing-1);
  padding: var(--spacing-2);
  width: fit-content;
}

.c-rules-view__html-configurations-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.c-rules-view__html-configuration-add-icon-container,
.c-rules-view__html-configuration-options-container {
  display: flex;
  flex: 1;
  gap: var(--spacing-2);
}

.c-rules-view__html-configuration-add-icon-container {
  justify-content: flex-end;
}

.c-rules-view__html-configuration-class-id-tag,
.c-rules-view__html-configuration-exclude-include, 
.c-rules-view__html-configuration-speech-translate {
  flex: 1;
}

.c-rules-view__html-configuration--exclude {
  border: 1px solid red;
}

.c-rules-view__html-configuration--include {
  border: 1px solid green;
}