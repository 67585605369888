.c-docs-content-page__list {
    list-style-type: none;
    padding: var(--g-spacing-4x-large) var(--g-spacing);
}

.c-docs-content-page__title {
    text-align: left;
}

@media screen and (min-width: 48.125em) {
    .c-docs-content-page__title {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 17.125rem auto
    }

    .c-docs-content-page__title h1 {
        margin: 0;
    }
}

@media screen and (min-width: 64em) {
    .c-docs-content-page__title {
        grid-template-columns: 20.125rem auto
    }
}

