.c-individual-welcome-form__body,
.c-agency-welcome-form__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: var(--g-spacing-x-large);
}

.c-individual-welcome-form__field,
.c-agency-welcome-form__field {
  margin-bottom: var(--g-spacing);
  text-align: left;
  width: 100%;
}

@media (min-width: 64em) {
  .c-individual-welcome-form__body,
  .c-agency-welcome-form__body {
    flex-direction: row;
  }

  .c-individual-welcome-form__field,
  .c-agency-welcome-form__field {
    margin-bottom: 0;
    max-width: 24rem;
  }

  .c-agency-welcome-form__field + .c-agency-welcome-form__field {
    margin-left: var(--g-spacing);
  }
}
