.c-external-link__tooltip {
  position: absolute;
}

.c-external-link__tooltip .c-tooltip__trigger {
  height: var(--g-spacing-2x-small);
  left: var(--g-spacing-2x-small);
  position: relative;
  top: var(--g-spacing-small);
  width: var(--g-spacing-2x-small);
}

.c-external-link__tooltip .c-tooltip__bubble {
  background-color: var(--g-color-grey-50);
  left: var(--g-spacing);
  position: relative;
  top: var(--g-spacing-x-small);
}

.c-external-link__tooltip-icon-link {
  position: relative;
  top: calc(var(--spacing-base) / 2);
}

.c-external-link__tooltip .c-tooltip__icon {
  height: var(--g-spacing-x-small);
  opacity: 0;
}

.c-external-link__tooltip .c-tooltip__trigger:focus {
  box-shadow: none;
}