.c-project-settings__list {
  border: 1px solid var(--g-color-grey-100);
  border-left: none;
  border-radius: var(--g-border-radius-small);
  list-style: none;
  padding: 0;
}

.c-project-settings__item {
  align-items: center;
  display: flex;
  margin: 0;
  padding: var(--g-spacing);
}

.c-project-settings__list .h-spacing-small {
  margin-bottom: 0;
}

.c-project-settings__label .h-spacing-small {
  margin-bottom: 0;
}

.c-project-settings__list .c-toggle-switch__wrapper--label-side-by-side .c-label {
  grid-template-columns: 110px 1fr;
}

@media (min-width: 64em) {
  .c-project-settings__list {
    max-height: 17rem;
  }
}
