.c-highlight-item {
  align-items: center;
  background-color: var(--g-color-white);
  border-left: 6px solid var(--g-color-grey-100);
  display: flex;
  margin-bottom: 0;
  margin-left: 0;
  padding: var(--g-spacing-small) var(--g-spacing);
  position: relative;
  transition: 200ms ease-in-out;
}

.c-highlight-item:hover {
  background-color: var(--g-color-grey-50);
}

.c-highlight-item--selected {
  border-color: var(--g-color-brand-tertiary);
}
