.c-welcome-step-progress {
  display: flex;
  margin-bottom: var(--g-spacing-large);
}

.c-welcome-step-progress__label {
  display: block;
  font-size: var(--g-font-size-small);
  margin-top: var(--g-spacing-x-small);
}

@media (min-width: 64em) {
  .c-welcome-step-progress {
    margin-bottom: var(--g-spacing-4x-large);
  }
}
