.c-plan-usage-container {
  background-color: var(--g-color-white);
  margin-bottom: var(--g-spacing-2x-large);
  padding-top: var(--g-spacing);
}

.c-plan-usage-inner {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.c-plan-usage {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-2x-large);
}

.c-plan-usage__links,
.c-plan-usage__details {
  align-items: center;
  display: flex;
}

.c-plan-usage__link {
  background-color: var(--purple-700);
  color: var(--g-color-white);
}


.c-plan-usage__items {
  align-items: center;
  display: grid;
  gap: var(--g-spacing);
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
}

.c-plan-usage__item {
  display: flex;
  flex-direction: column;
  min-width: 6rem;
}

.c-plan-usage__label {
  display: block;
  font-size: var(--g-font-size-small);
  line-height: 1;
}

.c-plan-usage__metrics {
  font-weight: var(--g-font-weight-light);
  line-height: 1;
}



.c-progress-circle {
  height: 2.35rem;
  margin-right: var(--g-spacing-small);
  min-width: 2.35rem;
  width: 2.35rem !important;
}

.c-plan-usage__link--secondary {
  background-color: var(--pear);
}


@media (max-width: 64em) {
  .c-plan-usage-container {
    align-items: flex-start;
  }

  .c-plan-usage__details,
  .c-plan-usage {
    align-items: start;
  }

  .c-plan-usage {
    flex-direction: column;
    gap: var(--g-spacing);
  }
}
