:root {
  /* Fonts */
  --g-font-primary: 'calibri';
  --g-font-secondary: 'rift';
  --font-header: rift;
  --font-body: calibri;

  /* COLOURS */
  --black: #000;
  --burgandy-100: #ebe7ed;
  --burgundy-400: #972868;
  --grey-900: #2a2e2f;
  --grey-800: #42536e;
  --grey-400: #a8b2b4;
  --grey-300: #cbccce;
  --grey-200: #ecedf0;
  --grey-100: #f6f6f7;
  --green-400: #4bc2ca;
  --green-500: #39acb4;
  --green-600: #239098;
  --green-700: #15666c;
  --color-primary: #4bc2ca;
  --purple-700: #673ab7;
  --monza: #c90035;
  --pear: #cddc39;
  --pizazz: #ff8a00;
  --red-400: #f44336;
  --red-300: #ff4d4d;
  --yellow-400: #ffc96c;
  --white: white;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4); /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8); /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12); /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16); /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20); /* 5rem */
  --spacing-24: calc(var(--spacing-base) * 24); /* 6rem */
  --spacing-28: calc(var(--spacing-base) * 28); /* 7rem */
  --spacing-32: calc(var(--spacing-base) * 32); /* 8rem */
  --spacing-36: calc(var(--spacing-base) * 36); /* 9rem */
  --spacing-40: calc(var(--spacing-base) * 40); /* 10rem */
  --spacing-48: calc(var(--spacing-base) * 48); /* 11rem */
  --spacing-52: calc(var(--spacing-base) * 52); /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60); /* 15rem */
  --spacing-72: calc(var(--spacing-base) * 72); /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80); /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88); /* 22rem */
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

label abbr {
  display: none;
}

/* h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-header);
  margin-bottom: 1.5rem;
  margin-top: 0;
}

h1,
.h1 {
  font-size: 3rem;
  line-height: 1;
}

h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.15;
}

h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.2;
}

h4,
.h4 {
  font-size: 1rem;
  line-height: 1.2;
}

p {
  line-height: 1.5;
  margin-top: 0;
} */

ul {
  margin-top: 0;
}

button {
  background-color: transparent;
  line-height: 1.3;
}

img {
  max-width: 100%;
}

fieldset {
  border: none;
}

table {
  border-collapse: collapse;
}

th {
  margin: 0;
  padding: 0;
}

td {
  background-color: var(--white);
}

input,
textarea {
  font-family: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

@media (min-width: 768px) {
  /* html {
    font-size: 1rem;
  }

  h1,
  .h1 {
    font-size: 4.5rem;
    line-height: 1;
  }

  h2,
  .h2 {
    font-size: 3.5rem;
    line-height: 1.1;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
    line-height: 1.3;
  }

  h4,
  .h4 {
    font-size: 1.15rem;
    line-height: 1.4;
  } */
}



.c-sidebar__overlay {
  z-index: 2;
}
/* temporary fix for ui navigation card coverlink issue */
.c-settings-button {
  z-index: 2;
}

.c-accordion__item-parent-container {
  margin-top: var(--g-spacing-x-small);
}

.c-toggle-switch-wrapper {
  width: 100%;
}

.c-label {
  font-size: inherit;
  font-weight: 600;
}
