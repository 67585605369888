.c-border-pending-installation {
  border: 1px solid #ff8a00;
  border-radius: 5px;
  margin-bottom: var(--g-spacing-2x-large);
  padding: var(--g-spacing-large) var(--g-spacing);
}

.c-border-installation-success {
  border: 1px solid #cddc39;
  border-radius: 5px;
  margin-bottom: var(--g-spacing);
  padding: var(--g-spacing-large) var(--g-spacing);
}

.c-polling-banner__icon {
  width: var(--spacing-8);
  height: var(--spacing-8);
}

.c-banner-polling-close-button {
  align-self: center;
  border: none;
}
