.c-kanzi-search-filter,
.c-kanzi-search-filter__input {
  align-items: center;
  display: flex;
  position: relative;
}

.c-kanzi-search-filter__icon {
  height: var(--g-spacing);
  left: var(--g-spacing-small);
  position: absolute;
  width: var(--g-spacing);
}

.c-kanzi-search-filter input {
  padding-left: var(--g-spacing-3x-large);
  min-width: 12rem;
}

.c-kanzi-search-filter__clear {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: var(--g-spacing);
  position: absolute;
  right: var(--g-spacing-small);
  width: var(--g-spacing);
}

.c-kanzi-search-filter__clear svg {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
