.c-page-wrapper {
  display: flex;
  width: 100%;
}

.c-page__sidebar-width {
  display: inline-block;
  width: 17rem;
}

.c-page__content {
  max-width: 1200px;
  margin: 3rem auto;
  width: 90%;
}

.c-information-box__link {
  color: var(--g-color-brand-tertiary);
}

.c-information-box__settings {
  display: flex;
  align-items: center;
}

@media (max-width: 64em) {
  .c-page__sidebar-width {
    width: 26rem;
  }
}

@media (min-width: 64em) {
  .c-page__content {
    margin-left: 19rem;
    margin-right: 4rem;
  }
}
