.c-style-settings {
  margin-bottom: var(--g-spacing-2x-large);
}

.c-style-settings__list {
  border: 1px solid var(--g-color-grey-100);
  border-radius: 10px;
  margin-bottom: var(--g-spacing-x-large);
  padding: 0;
}

.c-style-settings__item {
  list-style: none;
  margin: 0;
  padding: var(--g-spacing-x-small) var(--g-spacing);
}

.c-style-settings__item:not(:last-of-type) {
  border-bottom: 1px solid var(--g-color-grey-100);
}

.c-style-settings__item .c-checkbox {
  flex: 1;
}

.c-style-settings__item .c-label {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
}

.c-style-settings__item .c-checkbox__icon-container {
  cursor: pointer;
  margin-left: auto;
}

.c-style-settings__item .c-input {
  padding: 0;
  padding-left: var(--g-spacing-x-small);
}

.c-style-settings__item .c-select-list__input {
  padding: var(--g-spacing-2x-small) var(--g-spacing-2x-large) var(--g-spacing-2x-small) var(--g-spacing-small);
}

.c-style-settings__tooltip {
  margin-left: var(--g-spacing);
}

.c-style-settings__tooltip .c-tooltip__bubble {
  background-color: var(--g-color-white);
}

.c-style-settings__footer {
  display: flex;
  justify-content: flex-end;
}

.c-style-settings .c-label {
  flex: 1;
  font-size: var(--g-font-size);
  font-weight: var(--g-font-weight-semibold);
}

.c-style-settings .c-input-field {
  align-items: center;
}

.c-style-settings .h-spacing-small {
  margin-bottom: 0;
}
