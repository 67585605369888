.c-project-languages__list::-webkit-scrollbar {
  width: 6px;
}

.c-project-languages__list::-webkit-scrollbar-track {
  background-color: var(--g-color-grey-100);
}

.c-project-languages__list::-webkit-scrollbar-thumb {
  background-color: var(--g-color-brand-secondary);
  border-radius: 40px;
}

.c-project-languages__header {
  align-items: center;
  display: flex;
  margin-bottom: var(--g-spacing);
}

.c-project-languages__counter {
  font-weight: var(--g-font-weight-semibold);
  margin-right: var(--g-spacing);
}

.c-project-languages__list {
  border: 1px solid var(--g-color-grey-100);
  border-left: none;
  border-radius: var(--g-border-radius-small);
  list-style: none;
  overflow: auto;
  padding: 0;
}

.c-project-languages__label {
  flex: 1;
  font-size: var(--g-font-size);
  font-weight: normal;
  text-transform: capitalize;
}

.c-project-languages__label .h-spacing-small {
  margin-bottom: 0;
}

@media (min-width: 64em) {
  .c-project-languages__list {
    max-height: 17rem;
  }
}
