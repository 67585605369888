.c-kanzi-plugin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--g-spacing);
  position: relative !important;
  right: var(--g-spacing) !important;
}

.c-kanzi-tool__container {
  display: flex;
  justify-content: center;
}

.c-kanzi-tool {
  position: relative !important;
}

.c-kanzi-plugin {
  padding: var(--g-spacing);
}

.c-kanzi-expand-block__button {
  cursor: pointer !important;
  height: var(--spacing-14);
}

.c-kanzi-expand-block__button-icon--collapsed {
  transform: rotate(270deg) !important;
}

.c-kanzi-plugin__drag {
  position: relative !important;
}

.c-kanzi-drag {
  z-index: unset !important;
}

.c-kanzi-tool__language-menu {
  background-color: var(--g-color-grey-50) !important;
  display: block;
  right: unset !important;
}

.c-kanzi-tool__language-menu li {
  margin-left: 0 !important;
}

.c-kanzi-tool__volume-control {
  display: none !important;
}

.c-kanzi-tool__language-menu__no-text {
  color: var(--kanzi-color-blue-800);
  cursor: auto;
}

.c-kanzi-tool__language-menu__no-text:hover {
  color: unset !important;
  background-color: unset !important;
}

.c-kanzi-tool__list-item button {
  cursor: auto;
}

.c-kanzi-tool__list-item--dark-theme {
  background-color: rgb(66, 83, 110) !important;
  border-color: rgb(66, 83, 110) !important;
}

.c-kanzi-plugin .c-kanzi-tool {
  position: relative !important;
}

.c-kanzi-plugin .c-kanzi-expand-block__button-icon--collapsed {
  transform: rotate(270deg) !important;
}

.c-kanzi-plugin .c-kanzi-plugin__drag {
  position: relative !important;
}

.c-kanzi-plugin .c-kanzi-drag {
  z-index: unset !important;
}

.c-kanzi-plugin .c-kanzi-tool__language-menu {
  background-color: var(--g-color-grey-50) !important;
  display: block !important;
  right: unset !important;
}

.c-kanzi-plugin .c-kanzi-tool__language-menu li {
  margin-left: 0 !important;
}

.c-kanzi-plugin .c-kanzi-tool__language-menu__no-text {
  color: var(--kanzi-color-blue-800);
  cursor: auto;
}

.c-kanzi-plugin .c-kanzi-tool__language-menu__no-text:hover {
  color: unset !important;
  background-color: unset !important;
}

.c-kanzi-plugin .c-kanzi-tool__list-item button {
  cursor: auto;
}

.c-kanzi-plugin__drag--is-sticky {
  cursor: grab;
}

.c-kanzi-plugin__language-button,
.c-kanzi-plugin .c-kanzi-expand-block__button {
  cursor: pointer !important;
}

.c-kanzi-tool__list-item--large {
  height: var(--spacing-15) !important;
}

.c-kanzi-tool__list-item--medium {
  height: var(--spacing-14) !important;
}

.c-kanzi-tool__list-item--small {
  height: var(--spacing-13) !important;
}

.c-kanzi-toolbar__action-button--dark-theme {
  background-color: rgb(66, 83, 110) !important;
  border-color: rgb(66, 83, 110) !important;
}
