.c-general-view__instructions-modal {
    min-height: 95%;
}

.c-general-view__instructions-modal .c-dialog__header {
    display: none;
}

.c-general-view__instructions-modal-form-item {
    text-align: start;
}

.c-general-view__instructions-modal-form-item label {
    text-align: end;
    height: 100%;
}

.c-general-view__instructions-modal-form-item input, .c-general-view__instructions-modal-form-item textarea {
    border: 1px solid var(--g-color-grey-200);
    padding: var(--g-spacing-2x-small) var(--g-spacing-small);
}

.c-general-view__instructions-modal-form-item__textarea {
    padding: var(--g-spacing-2x-small) 0;
}

.c-general-view__instructions-modal-form-item__textarea textarea  {
    min-height: 50rem;
}

@media screen and (min-width: 32em) {
    .c-general-view__instructions-modal {
        min-height: 56rem;
    }

    .c-general-view__instructions-modal-form-item {
        align-items: center;
        display: -ms-grid;
        display: grid;
        gap: var(--g-spacing-x-large);
        -ms-grid-columns: var(--g-spacing-5x-large) auto;
        grid-template-columns: var(--g-spacing-5x-large) auto;
    }

    .c-general-view__instructions-modal-form-item__button {
        grid-template-columns: var(--g-spacing-5x-large) 9rem;
    }

    .c-general-view__instructions-modal-form-item__input label {
        position: relative;
        top: 0.4rem;
    }

    .c-general-view__instructions-modal-form-item__textarea textarea {
        min-height: 32.5rem;
    }
}

@media screen and (min-width: 48em) {
    .c-general-view__instructions-modal {
        min-height: 60rem;
    }

    .c-general-view__instructions-modal-form-item__textarea textarea {
        min-height: 33rem;
    }
}

@media screen and (min-width: 64em) {
    .c-general-view__instructions-modal {
        min-width: 56rem;
        min-height: 52rem;
    }

    .c-general-view__instructions-modal-form-item__textarea textarea {
        min-height: 25rem;
    }
}

@media screen and (min-width: 90em) {
    .c-general-view__instructions-modal {
        min-width: 65rem;
        min-height: 52rem;
    }

    .c-general-view__instructions-modal-form-item__textarea textarea {
        min-height: 24rem;
    }
}