.c-app-header--partner-logo .c-app-header__start {
  top: 0;
  padding: 0;
}

.c-app-header--partner-logo .c-app-header__image {
  height: 4.375rem;
  margin: 0;
}

.c-app-header--partner-logo .c-app-header__icon-link {
  line-height: 0;
}
