.c-subscription-details {
  align-items: center;
  display: flex;
}

.c-subscription-details__plans {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  font-size: var(--g-font-size-small);
  flex: 1;
  text-align: left;
}

.c-subscription-details__plan {
  margin-right: var(--g-spacing-large);
}

.c-subscription-details__plan * {
  margin-bottom: 0;
}

@media (min-width: 64em) {
  .c-subscription-details__plans {
    align-items: center;
    flex-direction: row;
  }
}
