.c-create-project-form__body {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--g-spacing-x-large);
}

.c-create-project-form__field {
  margin-bottom: var(--g-spacing);
  text-align: left;
  width: 100%;
}

@media (min-width: 64em) {
  .c-create-project-form__body {
    flex-direction: row;
  }
}
