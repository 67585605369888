.c-partner-page__agency-wrapper {
  display: flex;
  justify-content: space-between;
}

.c-partner-page__join-partner-button {
  margin: var(--g-spacing-large) 0;
}

.c-partner-page__section-intro-text {
  text-transform: uppercase;
}

.c-partner-page__section-heading {
  font-family: var(--font-header);
}

.c-partner-page__agency-content {
  margin-bottom: var(--spacing-12);
  max-width: 80%;
}

.c-partners-page__benefits-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-12);
}

.c-partner-page__scan-graphic-container {
  height: 27rem;
  margin-right: var(--g-spacing-large);
  position: relative;
  width: 24rem;
}

.c-partner-page__scan-graphic {
  height: 100%;
}

.c-partner-page__feature-section {
  border-right: 1px solid var(--white);
  padding: var(--spacing-4) var(--spacing-12);
  width: 33%;
}

.c-partner-page__feature-section--has-no-border {
  border-right: 1px solid transparent;
}

@media (max-width: 64em) {
  .c-partner-page__agency-wrapper {
    flex-direction: column;
  }

  .c-partner-page__feature-section {
    padding: var(--spacing-12) var(--spacing-12);
    width: 100%;
  }
}
