.c-reporting-page__table-toolbar {
  flex-direction: column;
  align-items: flex-start;
}

.c-reporting-page__table-toolbar .c-label {
  display: none;
}

.c-reporting-page__table-toolbar .ant-picker-input-active {
  padding: var(--g-spacing-x-small) 0;
}

.c-reporting-page__table-toolbar .ant-picker-range-separator {
  line-height: 2;
}

.c-reporting-page__table-toolbar .ant-picker {
  border-radius: var(--g-border-radius-small);
}

.c-external-link__new-window-icon {
  width: var(--g-spacing);
  height: var(--g-spacing);
}

.c-reporting-page__pagination .c-pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  margin-top: var(--spacing-8);
  justify-content: space-between;
  gap: var(--g-spacing);
}

.c-reporting-page__pagination .c-select-list__label {
  position: relative;
  top: var(--g-spacing-2x-small);
}

.c-reporting-page__pagination .c-input-field {
  display: flex;
  gap: var(--g-spacing-x-small);
  align-items: center;
  width: 100%;
}

@media screen and (min-width: 64em) {
  .c-reporting-page__table-toolbar {
    flex-direction: row;
  }
}

@media screen and (max-width: 37.5em) {
  .c-reporting-page__table-toolbar__middle {
    flex-direction: column;
  }
}
